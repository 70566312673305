/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

function fixHomeThumbLinks(windowHeight) {
  homeThumbLinksScrollListener(windowHeight);
  $( window ).scroll(function(){
    homeThumbLinksScrollListener(windowHeight);
  });
}

function homeThumbLinksScrollListener(windowHeight) {
  var link = $('.home-link:first-of-type');
  var offset = link.offset();
  var jama = offset.top + link.height() - windowHeight;
  if ($(window).scrollTop() > jama) {
    $('.home-link .text-container').removeClass('fixed-title');
  }
  else {
    $('.home-link .text-container').addClass('fixed-title');
  }
}

function resizeCustomerHeights() {
  if ($( window ).width()>768) {
    $('.section').each(function(){
      $(this).children('div').equalHeights();
    });
  }
  else {
    $('.section div').css('height','auto');
    $('.section .image img').css('padding','');
  }
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(function() {
          $('a[href^="#"]').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top - 80
                }, 300);
                return false;
              }
            }
          });
        });

        $(".post-box p").find('a img').each(function(){
           $(this).parent().parent("p").css( "max-width", "100%" );
        });

        $(".post-box p").find('img').each(function(){
           $(this).parent("p").css( "max-width", "100%" );
        });

        $("#getinfo_button_bottom").click(function(){
          $("#bottom-contact-form-area").slideToggle();
        });

        $("a").click(function () {
          var linkvalue = $(this).attr("href");
            if (linkvalue === "#getintouch") {
              $(window).scrollTo($("#bottom-content"), 800);
              if ($("#bottom-contact-form-area").is(":hidden")) {
                $("#bottom-contact-form-area").slideToggle();
              }
            }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        // Main Menu
        if ($(window).scrollTop() > 10) {
            $('#menu-main-menu').fadeOut();
          }
          else {
            $('#menu-main-menu').fadeIn();
          }
        $( window ).scroll(function(){
          if ($(window).scrollTop() > 10) {
            $('#menu-main-menu').fadeOut();
          }
          else {
            $('#menu-main-menu').fadeIn();
          }
        });
        // Home Thumbs
        var windowHeight = $(window).height();
        if (windowHeight<801 && $(window).width()>767) {
          fixHomeThumbLinks(windowHeight);
        }
        $(window).resize(function(){
          var windowHeight = $(window).height();
          if (windowHeight<801 && $(window).width()>767) {
            fixHomeThumbLinks(windowHeight);
          }
          else {
            $('.home-link .text-container').removeClass('fixed-title');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_customers_new': {
      init: function() {
        // JavaScript to be fired on the about us page
        resizeCustomerHeights();

        $(window).resize(function () {
            waitForFinalEvent(function(){
              resizeCustomerHeights();
            }, 500, "customerresize");
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_about_us': {
      init: function() {
        $('.c-team-member--bio .c-team-member__image').click(
          function() {
            $( this ).parent().children('.c-team-member__bio').slideToggle();
          }
        );
        $('.c-team-member--bio .c-team-member__name').click(
          function() {
            $( this ).parent().parent().children('.c-team-member__bio').slideToggle();
          }
        );
        $('#page-hero').click(
          function() {
            $('body').addClass('about-us-mapmode');
          }
        );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
